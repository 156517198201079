import styles from './index.module.css';
import PopupCalendly from '../../CalendlyPopup';

const HeroSection = (props) => { 
    return (
        <section className={styles.container} id={props.id}>
            <div className={styles.text_card}>
                <div className={styles.text_heading}>
                    {props.text_content}
                    <br />
                    <PopupCalendly text={props.button_value} url={process.env.REACT_APP_CALENDLY_URL} styles={styles} />
                    {/* <InlineWidget url="https://calendly.com/atuldubey/introduction-to-dataturtles" /> */}
                </div>
            </div> 
            <div className={styles.image_card}>
                <img
                    className={styles.image}
                    src={props.Hero_Image}
                    alt={"Hero_image"}
                />
            </div>
        </section>  
    )
}

export default HeroSection;