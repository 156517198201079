import styles from './index.module.css';
import Supported_company_logo from "../../../Assets/About us/supported by/wf.png";
import Supported_company_logo2 from "../../../Assets/About us/supported by/aic.png";
import Supported_company_logo3 from "../../../Assets/About us/supported by/lnct.png";
import Supported_company_logo4 from "../../../Assets/About us/supported by/100xvc.png";


const SupportedBy = ({title}) => {
    return (
        <>
            <div className={styles.container}> 
                
                <div className={styles.logos}>
                    <h1 className={styles.heading}>{title}</h1>
                    <span className={styles.card_image_span}>
                        <img className={styles.card_image} src={Supported_company_logo} alt={"logo"} /> 
                    </span>
                    <span className={styles.card_image_span}>
                        <img className={styles.card_image} src={Supported_company_logo2} alt={"logo"} /> 
                    </span>
                    <span className={styles.card_image_span}>
                        <img className={styles.card_image} src={Supported_company_logo3} alt={"logo"} /> 
                    </span>
                    <span className={styles.card_image_span}>
                        <img className={styles.card_image} src={Supported_company_logo4} alt={"logo"} /> 
                    </span>
                </div>  
            </div>
        </>
    )
}

export default SupportedBy;
