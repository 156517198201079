import React, { useEffect } from 'react';
import HeroSection from '../../Components/Card/Hero';
import Hero_Image from '../../Assets/Homepage/hero.png';
import Image from '../../Assets/Hardware/hardware1.svg';
import TrustedBy from '../../Components/Card/TrustedBy';
import CardIOT from '../../Components/Card/CardIot';
import ExpertiseCard from '../../Components/Card/ExpertiseCard';
import Portfolio from '../../Components/Card/Portfolio';
import SEO from '../../Components/Seo';


export const EmbeddedHardware = () => {
    useEffect(()=>{
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    },[]);
    return (
        <>
            <SEO
                title={"Embedded and Hardware Engineering services"} 
                description={"Internet of Things (IoT)."} 
            />
            <main style={{width: "100%", height: "100%"}}>
                <HeroSection 
                    Hero_Image={Hero_Image}
                    text_content={"Complete hardware design and development services for your smart connected products"}
                    button_value={"Book a free consultation"}
                /> 
                <TrustedBy title={"Trusted By"} />    
                <CardIOT 
                    title={"Hardware Development"} 
                    image_url={Image}
                    content_first={`Software is useless without right kind of hardware. With the technology getting cheaper and accessible to everyone, businesses are looking to add automation and some sort of innovation in dumbest of the devices.`}
                    content_sec={`At DataTurtles, we understand client’s requirements in depth and then suggest them the most feasible hardware architecture to go with. We help them develop the prototype at faster pace, help in validating the product in real world scenarios, and get the product to market at a fast pace.`} 
                />
                <ExpertiseCard
                    title={"IoT Services to build a connected world"} 
                    button_value={"Book a free consultation"}
                />
                <Portfolio title={"Portfolio"} iot={true}/>
            </main>
        </>
    )
}