import styles from './index.module.css';
import CarouselP from './CarouselP';
import Portfolio_image from "../../../Assets/Homepage/Portfolio/desk.jpg";
import Portfolio_image2 from "../../../Assets/Homepage/Portfolio/urban_garden.jpg";
import Portfolio_image3 from "../../../Assets/Homepage/Portfolio/chem_demand.jpeg";
import Circleci from "../../../Assets/Homepage/Portfolio/project details/circleci.png";
import aws from "../../../Assets/Homepage/Portfolio/project details/aws.png";
import eink from "../../../Assets/Homepage/Portfolio/project details/eink.png";
import esp from "../../../Assets/Homepage/Portfolio/project details/esp.png";
import c from "../../../Assets/Homepage/Portfolio/project details/c.png";
import freertos from "../../../Assets/Homepage/Portfolio/project details/freertos.png";
import plotly from "../../../Assets/Homepage/Portfolio/project details/plotly.png";
import scikitlearn from "../../../Assets/Homepage/Portfolio/project details/scikitlearn.png";
import ExpressJs from "../../../Assets/Homepage/Portfolio/project details/expressjs.png";
import python from "../../../Assets/Homepage/Portfolio/project details/python.png";
import pandas1 from "../../../Assets/Homepage/Portfolio/project details/pandas1.png";
import pandas2 from "../../../Assets/Homepage/Portfolio/project details/pandas2.png"; 
import mongo from "../../../Assets/Homepage/Portfolio/project details/mongo.png";
import postgresql from "../../../Assets/Homepage/Portfolio/project details/postgresql.png";
import react_native from "../../../Assets/Homepage/Portfolio/project details/react-native-development-company.png";
import react from "../../../Assets/Homepage/Portfolio/project details/react.png";
import redis from "../../../Assets/Homepage/Portfolio/project details/redis.png";
import wifi from "../../../Assets/Homepage/Portfolio/project details/wifi.png";


const Portfolio = ({title, sub_title, ...props}) => { 
    const portfolioFirstDivInfo = {
        heading: 'Smart Desk for an office furniture compnay',
        sub_heading: 'A smart solution which creates a micro ecosystem to enable employee well-being in a closed office environment.',
        image_url: Portfolio_image,
        platform: 'Platforms',
        circleci_url: Circleci,
        platform_url: esp,
        technologies: 'Technologies',
        technologies_url : [
            c,
            freertos,
            wifi,
            eink,
        ],
    }
    const portfolioSecondDivInfo = {
        heading: 'Urban Gradening Marketplace',
        sub_heading: 'Cloud marketplace helping vendors & buyers in selling & buying urban gardening products online.',
        image_url: Portfolio_image2,
        platform: 'Platforms',
        platform_url:[
            Circleci, 
            aws, 
        ],
        technologies: 'Technologies',
        technologies_url : [
            ExpressJs,
            mongo,
            postgresql,
            react,
            react_native,
            redis,
            redis,
            ExpressJs,
        ],
    }
    
    const portfolioThirdDivInfo = {
        heading: 'Demand forcasting for a chemical industry',
        sub_heading: 'Helped the end-user with chemical inventory management by forecasting the chemical consumption per day given multidimensional time-series data.',
        image_url: Portfolio_image3,
        platform: 'Platforms',
        platform_url: aws, 
        technologies: 'Technologies',
        technologies_url : [
            pandas1,
            pandas2,
            python,
            plotly,
            scikitlearn,
        ],
    }   
    return (
        <>
            <div id='portfolios' className={`${styles.container}`}>
                <h1 className={styles.heading}>{title}</h1>
                <h2 className={styles.subheading}>{sub_title}</h2>
                <div style={{ maxWidth: "100%", marginLeft: 'auto', marginRight: 'auto', marginTop: "10px" }}>
                <CarouselP> 
                    <div className={styles.image_container}>
                            <div className={styles.image_card_container}>
                                <img 
                                    className={styles.images}
                                    src={portfolioFirstDivInfo.image_url} 
                                    alt={'portfolio_iot'} 
                                />
                            </div>
                            <div>
                                <span className={styles.image_card}>{portfolioFirstDivInfo.heading}</span>
                            </div>
                            <div className={styles.image_overlay}>
                                <h1 className={styles.image_overlay_heading}>{portfolioFirstDivInfo.heading}</h1>
                                <p className={styles.image_overlay_p}>
                                    {portfolioFirstDivInfo.sub_heading}
                                </p>
                                <div className={styles.image_overlay_card}>
                                    <div>
                                        <h3 className={styles.image_overlay_subheading}>{portfolioFirstDivInfo.platform}</h3>
                                        <div className={styles.image_size_outer_div_longwidth}>
                                            <img 
                                                className={styles.image_size} 
                                                src={portfolioFirstDivInfo.platform_url} 
                                                alt={'esp'} 
                                                width={120} 
                                                height={20}
                                            /> 
                                        </div>   
                                    </div>
                                    <div>
                                        <h3 className={styles.image_overlay_subheading}>{portfolioFirstDivInfo.technologies}</h3>
                                        <div className={styles.image_overlay_card_image}>
                                            <div className={styles.image_size_outer_div}>
                                                <img  
                                                    className={styles.image_size} 
                                                    src={portfolioFirstDivInfo.technologies_url[0]} 
                                                    alt={'c'} 
                                                    width={40}
                                                    height={20}
                                                />
                                            </div>
                                            <div className={styles.image_size_outer_div}>
                                                <img  
                                                    className={styles.image_size} 
                                                    src={portfolioFirstDivInfo.technologies_url[1]} 
                                                    alt={'freertos'} 
                                                    width={40} 
                                                    height={20}
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.image_overlay_card_image}>
                                            <div className={styles.image_size_outer_div}>
                                                <img  
                                                    className={styles.image_size} 
                                                    src={portfolioFirstDivInfo.technologies_url[2]} 
                                                    alt={'wifi'} 
                                                    width={40} 
                                                    height={20}
                                                />
                                            </div>
                                            <div className={styles.image_size_outer_div}>
                                                <img  
                                                    className={styles.image_size} 
                                                    src={portfolioFirstDivInfo.technologies_url[3]} 
                                                    alt={'eink'} 
                                                    width={40} 
                                                    height={20}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.image_container}>
                            <div className={styles.image_card_container}>
                                <img
                                    className={styles.images}
                                    src={portfolioSecondDivInfo.image_url} 
                                    alt={'portfolio_software_development'}   
                                />
                            </div>
                            <div>
                                <span className={styles.image_card}>{portfolioSecondDivInfo.heading}</span>
                            </div>
                            <div className={styles.image_overlay}>
                                <h1 className={styles.image_overlay_heading}>{portfolioSecondDivInfo.heading}</h1>
                                <p className={styles.image_overlay_p}>
                                    {portfolioSecondDivInfo.sub_heading}
                                </p>
                                <div className={styles.image_overlay_card}>
                                    <div>
                                        <h3 className={styles.image_overlay_subheading}>{portfolioSecondDivInfo.platform}</h3>
                                        <div className={styles.image_size_outer_div}>
                                            <img  
                                                className={styles.image_size} 
                                                src={portfolioSecondDivInfo.platform_url[0]} 
                                                alt={'circleci'} 
                                                width={30} 
                                                height={20}
                                            /> 
                                        </div>
                                        <div className={styles.image_size_outer_div}>
                                        <img  
                                            className={styles.image_size} 
                                            src={portfolioSecondDivInfo.platform_url[1]} 
                                            alt={'aws'} 
                                            width={30} 
                                            height={20}
                                        />
                                        </div>    
                                    </div>
                                    <div>
                                        <h3 className={styles.image_overlay_subheading}>{portfolioSecondDivInfo.technologies}</h3>
                                        <div className={styles.image_overlay_card_image}>
                                            <div className={styles.image_size_outer_div_longwidth}>
                                                <img  
                                                    className={styles.image_size} 
                                                    src={portfolioSecondDivInfo.technologies_url[0]} 
                                                    alt={'ExpressJs'} 
                                                    width={120} 
                                                    height={40}
                                                />
                                            </div>
                                            <div className={styles.image_size_outer_div}>
                                                <img  
                                                    className={styles.image_size} 
                                                    src={portfolioSecondDivInfo.technologies_url[1]} 
                                                    alt={'mongo'} 
                                                    width={40} 
                                                    height={40}
                                                />
                                            </div>
                                            <div className={styles.image_size_outer_div}>
                                                <img  
                                                    className={styles.image_size} 
                                                    src={portfolioSecondDivInfo.technologies_url[2]} 
                                                    alt={'postgresql'} 
                                                    width={40} 
                                                    height={40}
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.image_overlay_card_image}>
                                            <div className={styles.image_size_outer_div}>
                                                <img  
                                                    className={styles.image_size} 
                                                    src={portfolioSecondDivInfo.technologies_url[3]} 
                                                    alt={'react'} 
                                                    width={40} 
                                                    height={40}
                                                />
                                            </div>
                                            <div className={styles.image_size_outer_div}>
                                                <img  
                                                    className={styles.image_size} 
                                                    src={portfolioSecondDivInfo.technologies_url[4]} 
                                                    alt={'react_native'} 
                                                    width={40} 
                                                    height={40}
                                                />
                                            </div>
                                            <div className={styles.image_size_outer_div}>
                                                <img  
                                                    className={styles.image_size} 
                                                    src={portfolioSecondDivInfo.technologies_url[5]} 
                                                    alt={'redis'} 
                                                    width={40} 
                                                    height={40}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.image_container}>
                            <div className={styles.image_card_container}>
                                <img 
                                    className={styles.images}
                                    src={portfolioThirdDivInfo.image_url} 
                                    alt={'portfolio_machin_learning'} 
                                />
                            </div>
                            <div>
                                <span className={styles.image_card}>{portfolioThirdDivInfo.heading}</span>
                            </div>
                            <div className={styles.image_overlay}>
                                <h1 className={styles.image_overlay_heading}>{portfolioThirdDivInfo.heading}</h1>
                                <p className={styles.image_overlay_p}>
                                    {portfolioThirdDivInfo.sub_heading}
                                </p>
                                <div className={styles.image_overlay_card}>
                                    <div>
                                        <h3 className={styles.image_overlay_subheading}>{portfolioThirdDivInfo.platform}</h3>
                                        <div className={styles.image_size_outer_div}>
                                            <img  
                                                className={styles.image_size} 
                                                src={portfolioThirdDivInfo.platform_url} 
                                                alt={'aws'} 
                                                width={40} 
                                                height={40}
                                            /> 
                                        </div>   
                                    </div>
                                    <div>
                                        <h3 className={styles.image_overlay_subheading}>{portfolioThirdDivInfo.technologies}</h3>
                                        <div className={styles.image_overlay_card_image}>
                                            <div className={styles.image_size_outer_div_longwidth}>
                                                <img  
                                                    className={styles.image_size} 
                                                    src={portfolioThirdDivInfo.technologies_url[0]} 
                                                    alt={'pandas1'} 
                                                    width={80} 
                                                    height={25}
                                                />
                                            </div>
                                            <div className={styles.image_size_outer_div_longwidth}>
                                                <img  
                                                    className={styles.image_size} 
                                                    src={portfolioThirdDivInfo.technologies_url[1]} 
                                                    alt={'pandas2'} 
                                                    width={80} 
                                                    height={25}
                                                />
                                            </div>
                                        </div>
                                        <div className={styles.image_overlay_card_image}>
                                            <div className={styles.image_size_outer_div}>
                                                <img  
                                                    className={styles.image_size} 
                                                    src={portfolioThirdDivInfo.technologies_url[2]} 
                                                    alt={'python'} 
                                                    width={40} 
                                                    height={40}
                                                />
                                            </div>
                                            <div className={styles.image_size_outer_div_longwidth}>
                                                <img  
                                                    className={styles.image_size} 
                                                    src={portfolioThirdDivInfo.technologies_url[3]} 
                                                    alt={'plotly'} 
                                                    width={100} 
                                                    height={30}
                                                />
                                            </div>
                                            <div className={styles.image_size_outer_div_longwidth}>
                                                <img  
                                                    className={styles.image_size}
                                                    src={portfolioThirdDivInfo.technologies_url[4]} 
                                                    alt={'scikitlearn'} 
                                                    width={100} 
                                                    height={30}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>              
                </CarouselP>
                </div>
            </div>
        </>
    )
}

export default Portfolio;
