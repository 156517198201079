import classes from "./index.module.css";
import CarouselCard from "./index";

const Carousel = ({title}) => {
   
    return (
        <div id="how_we_work" className={classes.carousel_container}>
            <h1 className={classes.heading}>{title}</h1>
            <CarouselCard />
        </div>
    )
}

export default Carousel
