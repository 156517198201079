import styles from './WhyUs.module.css';
import WhyUsCard from './WhyUsCard';
import Why_us_image from "../../../Assets/Homepage/Why DT/agile.svg";
import Why_us_image2 from "../../../Assets/Homepage/Why DT/team.svg";
import Why_us_image3 from "../../../Assets/Homepage/Why DT/price.svg";
import Why_us_image4 from "../../../Assets/Homepage/Why DT/record.svg";


const WhyUs = ({title, sub_title}) => {
    return (
        <>
            <div className={styles.whyus_container}>
                <h1 className={styles.whyus_heading}>{title}</h1>
                <h2 className={styles.whyus_subheading}>{sub_title}</h2>
                <div className={styles.whyus_logos}>
                    <WhyUsCard image={Why_us_image} text={"Innovative models"} />
                    <WhyUsCard image={Why_us_image2} text={"Proven Development"} />
                    <WhyUsCard image={Why_us_image3} text={"Strong team"} /> 
                    <WhyUsCard image={Why_us_image4} text={"Cost-effective services"} />
                </div> 
               
            </div>
        </>
    )
}

export default WhyUs