import styles from "./index.module.css";
const Card = (props) => {
    return (
        <>
            <div className={styles.card}>
                <div className={styles.image_card}>
                    <img 
                        className={styles.image} 
                        src={props.image} 
                        alt={'I_served_images'}
                    />
                </div>
                <div>
                    <p className={styles.card_p}>{props.text}</p>
                </div>
            </div>
        </>
    )
}

export default Card