import styles from "./index.module.css";

const Experience = () => {
    return (
        <div className={styles.container}>
            <div className={styles.card}>
                <h1 className={styles.heading}>6+</h1>
                <p className={styles.p}>
                    Projects Delivered
                </p>
            </div>
            <div className={styles.card}>
                <h1 className={styles.heading}>7+</h1>
                <p className={styles.p}>
                    Professionals in the  team
                </p>
            </div>
            <div className={styles.card}>
                <h1 className={styles.heading}>5+</h1>
                <p className={styles.p}>
                    Years of Experience
                </p>
            </div>
        </div>
    )
}

export default Experience
