import styles from './index.module.css';
import Card from './Card';
import Portfolio_image from "../../../Assets/Homepage/Industries/agri.svg";
import Portfolio_image1 from "../../../Assets/Homepage/Industries/edtech.svg";
import Portfolio_image2 from "../../../Assets/Homepage/Industries/chemtech.svg";
import Portfolio_image3 from "../../../Assets/Homepage/Industries/consumer.svg";
import Portfolio_image4 from "../../../Assets/Homepage/Industries/furniture.svg";


const I_served = ({title, sub_title}) => {
    return (
        <>
            <div className={styles.container}>
                <h1 className={styles.heading}>{title}</h1>
                <h2 className={styles.subheading}>{sub_title}</h2>
                <div className={styles.logos}>
                    <Card image={Portfolio_image} text={"Agritech Industry"} />
                    <Card image={Portfolio_image1} text={"Edtech Industry"} />
                    <Card image={Portfolio_image2} text={"Chemical Industry"} /> 
                    <Card image={Portfolio_image3} text={"Consumer Appliance"} />
                    <Card image={Portfolio_image4} text={"Furniture Industry"} /> 
                </div>   
            </div>
        </>
    )
}

export default I_served;