import { useState } from "react";
import classes from "./Nav.module.css";
import logo from "../../Assets/Homepage/DT_logo/dt_logo.svg";
import { VscMenu, VscClose } from 'react-icons/vsc';
import { Link, NavLink, useLocation } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';

const Navigation = () => {
    const [click, setClick] = useState(false);
    let currentPath = useLocation();
    currentPath = (currentPath.pathname + currentPath.hash).trim();
    const handleClick = () => {
        setClick(false);
    }
    const handleRouteClick = ()=>{
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setClick(false);
    }
    const handleMobileClick = () => setClick(!click);
    return (
        <>
            <nav>
                <div className={classes.nav}>
                <div className={classes.logo} onClick={handleRouteClick}>
                    <Link to={"/"}>
                        <img src={logo} alt={"dataturtle_logo"} />
                    </Link>   
                </div>
                <div className={`${classes.list_item} ${click ? `${classes.list_item_is_mobile_view}`: ''}`}>
                    <div className={classes.item} onClick={handleRouteClick}>
                        <NavLink to={"/"} className={ currentPath === "/" ? ` ${classes.isActive}` : ` ${classes.isNotActive}`}>
                            Home
                        </NavLink>
                    </div>
                    <div className={classes.item} onClick={handleClick}>
                        <HashLink smooth to={"/#services"} className={ currentPath === "/#services" ? ` ${classes.isActive}` : ` ${classes.isNotActive}`}>
                            What we do
                        </HashLink>
                    </div>
                    <div className={classes.item} onClick={handleClick}>
                        <HashLink smooth to={"/#how_we_work"} className={ currentPath === "/#how_we_work" ? ` ${classes.isActive}` : ` ${classes.isNotActive}`}>
                            How we work
                        </HashLink>
                    </div>
                    <div className={classes.item} onClick={handleClick} >
                        <HashLink smooth to={"/#portfolios"} className={ currentPath === "/#portfolios" ? ` ${classes.isActive}` : ` ${classes.isNotActive}`}>
                            Portfolio
                        </HashLink>
                    </div>
                    <div className={classes.item} onClick={handleRouteClick}>
                        <NavLink to={"/about"} className={({ isActive }) => (isActive ? ` ${classes.isActive}` : ` ${classes.isNotActive}`)}>
                            About Us
                        </NavLink>
                    </div>   
                    <div className={classes.item} onClick={handleClick}>
                        <Link 
                            to={"/#tally-contact"}
                            className={classes.btn} 
                            data-tally-open="mB6vQm" 
                            data-tally-layout="modal" 
                            data-tally-align-left="1" 
                            data-tally-hide-title="1" 
                            data-tally-emoji-text="👋" 
                            data-tally-emoji-animation="wave" 
                            data-tally-auto-close="0"
                        >
                            Get In Touch
                        </Link>
                    </div>
                </div>
                </div>
                <div className={classes.mobile_menu} onClick={handleMobileClick}>
                    {click ? 
                        <VscClose className={classes.menu_icon} />
                    : 
                        <VscMenu className={classes.menu_icon} />
                    }
                </div>
                            
            </nav>
        </>
    )
}

export default Navigation
