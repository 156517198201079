import React, { useState } from 'react';
import { PopupModal } from "react-calendly";
import Button from '../Atom/Button';

const PopupCalendly = (props) => {
    const [isOpen, setIsOpen]  = useState(false);
    // const pageSettings={
    //     backgroundColor: "ffffff",
    //     hideEventTypeDetails: false,
    //     hideLandingPageDetails: false,
    //     primaryColor:'00a2ff',
    //     textColor: '4d5055',
    // }
    return (
        <div>
            <Button
                className={props.styles.btn}
                onClick={() => setIsOpen(true)}
            >
                {props.text}
            </Button>
            <PopupModal
                url={props.url}
                // pageSettings={pageSettings}
                onModalClose={() => setIsOpen(false)}
                open={isOpen}
                rootElement={document.getElementById("root")}
            />
        </div>
    );
};

export default PopupCalendly;