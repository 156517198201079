import styles from './index.module.css';
import {FaLinkedin, FaGlobe} from 'react-icons/fa';
import EmployeeImage from "../../../Assets/About us/team/ad.jpg";
import EmployeeImage2 from "../../../Assets/About us/team/kp.jpg";

const CoreTeam = ({title}) => {
    const data = [
        {
            name: "Atul Dubey",
            sub_heading: "Founder",
            image_url: EmployeeImage,
            skills: [
                "IoT Solutions",
                "Hardware Development",
                "PCB Designing",
                "Firmware Designing",
            ],
        }, 
        // {
        //     name: "Karan Patak",
        //     sub_heading: "Co-Founder",
        //     image_url: EmployeeImage2,
        //     skills: [
        //         "Applied ML",
        //         "Software Development",
        //         "Mobile App",
        //         "Full Stack",
        //     ],
        // }, 
    ];
    return (
        <div className={styles.container}>
            <h1 className={styles.heading}>{title}</h1>
            <div className={styles.card_container}>
                {data.map((item, i)=>{
                    return (
                        <div className={`${styles.card} ${styles.effect}`} key={i}>
                            <div className={styles.withOutHover}>
                                <img className={styles.image} src={item.image_url} alt={"Team Member"} width={182} height={182}/>
                                <div className={styles.card_info}>
                                    <h2 className={styles.sub_heading}>{item.name}</h2>
                                    <p className={styles.p}><small>{item.sub_heading}</small></p>
                                </div>
                            </div>
                            
                            <div className={styles.cardhover_info }>
                                <h2 className={styles.sub_heading}>{item.name}</h2>
                                <div className={styles.box}>
                                    <div className={styles.btn}>{item.skills[0]}</div>
                                    <div className={styles.btn}>{item.skills[1]}</div>
                                </div>
                                <div className={styles.box}>
                                    <div className={styles.btn}>{item.skills[2]}</div>
                                    <div className={styles.btn}>{item.skills[3]}</div>
                                </div>
                                <div className={styles.box_icons}>
                                    <FaLinkedin color='#005C99' size={25} />
                                    <FaGlobe color='#005C99' size={25} />
                                </div>
                            </div>
                        </div>
                    )
                })} 
            </div>
        </div>
    )
}

export default CoreTeam
