import styles from './Client.module.css';
import Client_company_logo from "../../../Assets/Homepage/clients/up.png";
import Client_company_logo2 from "../../../Assets/Homepage/clients/amare.png";
import Client_company_logo3 from "../../../Assets/Homepage/clients/zblok-removebg-preview.png";
import Client_company_logo4 from "../../../Assets/Homepage/clients/smooth_electrical.png";


const Clients = ({title}) => {
    return (
        <div className={styles.client_container}>   
            <div className={styles.client_logos}>
                <h1 className={styles.client_heading}>{title}</h1>
                <span className={styles.client_card_image_span}>
                    <img className={styles.client_card_image} src={Client_company_logo} alt={"logo"} /> 
                </span>
                <span className={styles.client_card_image_span}>
                    <img className={styles.client_card_image} src={Client_company_logo2} alt={"logo"} /> 
                </span>
                <span className={styles.client_card_image_span}>
                    <img className={styles.client_card_image} src={Client_company_logo3} alt={"logo"} /> 
                </span>
                <span className={styles.client_card_image_span}>
                    <img className={styles.client_card_image} src={Client_company_logo4} alt={"logo"} /> 
                </span>
            </div>  
        </div>
    )
}

export default Clients
