import styles from './index.module.css';
import Trusted_company_logo from "../../../Assets/Homepage/clients/up.png";
import Trusted_company_logo2 from "../../../Assets/Homepage/clients/smooth_electrical.png";


const TrustedBy = ({title}) => {
    return (
        <>
            <div className={styles.container}> 
                
                <div className={styles.logos}>
                    <h1 className={styles.heading}>{title}</h1>
                    <span className={styles.card_image_span}>
                        <img className={styles.card_image} src={Trusted_company_logo} alt={"logo"} /> 
                    </span>
                    <span className={styles.card_image_span}>
                        <img className={styles.card_image} src={Trusted_company_logo2} alt={"logo"} /> 
                    </span>
                </div>  
            </div>
        </>
    )
}

export default TrustedBy;
