import React, { useEffect} from 'react';
import HeroSection from '../Components/Card/Hero';
import SEO from '../Components/Seo';
import Hero_Image from '../Assets/Homepage/hero.png';
import Clients from '../Components/Card/Client/Clients';
import WhyUs from '../Components/Card/Whyus/WhyUs';
import Services from '../Components/Card/Services/Services';
import Carousel from '../Components/Card/Carousel/Carousel';
import Portfolio from '../Components/Card/Portfolio';
import Served from '../Components/Card/Served';

export const Home = () => {
    useEffect(()=>{
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    return (
        <>
            <SEO
                title="DataTurtles | Home"
                description="We are a services company consisting of a team of young and enthusiastic professionals with experience in IoT, Hardware design, AI/ML, Data Science, Web Development, App Development, and Cyber Security. We have partnered with a manufacturing company for our client's electronics manufacturing requirements."
                name='DataTurtles'
                type='Portfolio' 
            />
            <main style={{width: "100%", height: "100%"}}>
                <HeroSection 
                    Hero_Image={Hero_Image}
                    text_content={"Enabling Business with Technology"}
                    button_value={"Book a free consultation"}
                    id={"home"}
                />     
                <Clients title={"Clientele"} />
                <WhyUs 
                    title={"Why Us?"}
                    sub_title={"Strategically positioned to deliver outstanding user experiences and respond to the evolving needs through emerging technologies"} 
                />
                
                <Services 
                    title={"Services"}
                    sub_title={"Looking to leverage technology for business growth or Require technology partnership for product development"} 
                />
                <Carousel title={"How we Work"} />
                <Portfolio 
                    title={"Portfolio"}
                    sub_title={"Some of the works that we have delivered for our clients"}
                />
                <Served
                    title={"Industries Served"} 
                    sub_title={"We specialize in technology and serve different industries"}
                />    
            </main>
        </>
    )
}
