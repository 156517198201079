import {useState, useEffect} from 'react';
import styles from './index.module.css';
const portfolioImages = [1, 2, 3];
const CarouselP = ({children}) => {
    const [currentIndex, setCurrentIndex] = useState(1);
    const [length, setLength] = useState(children.length);
    const [touchPosition, setTouchPosition] = useState(null);
    const goToSlide = (index) => {
        setCurrentIndex(index);
    }
    const handleTouchStart = (e) => {
        const touchDown = e.touches[0].clientX
        setTouchPosition(touchDown)
    }
    const handleTouchMove = (e) => {
        const touchDown = touchPosition
    
        if(touchDown === null) {
            return
        }
    
        const currentTouch = e.touches[0].clientX
        const diff = touchDown - currentTouch
    
        if (diff > 3) {
            next()
        }
    
        if (diff < -3) {
            prev()
        }
    
        setTouchPosition(null)
    }
    const next = () => {
        if (currentIndex < (length - 1)) {
            setCurrentIndex(prevState => prevState + 1)
        }
    }
    
    const prev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(prevState => prevState - 1)
        }
    }

    useEffect(() => {
        setLength(children.length)
    }, [children])

    return (
        <div className={styles.carousel_container}>
            <div className={styles.carousel_wrapper}>
                <div className={styles.carousel_content_wrapper}  onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
                    <div className={currentIndex ? `${styles.carousel_content} ${styles.active_carosel_content}`: `${styles.carousel_content} ${styles.show_3}`} 
                    style={{ transform: `translateX(${currentIndex === 0 ? '+25': '-'}${(currentIndex === 1 ? '25' : "")}${(currentIndex === 2 ? '75' : "")}%)`}}>
                        {children}
                    </div>
                    <div className={currentIndex ? `${styles.mob_carousel_content} ${styles.active_carosel_content}`: `${styles.mob_carousel_content}`} 
                    style={{ transform: `translateX(-${currentIndex * 100}%)`}}>
                        {children}
                    </div>
                </div>
                
            </div>
            <div className={`${styles.carousel__slides}`}>
                        {portfolioImages.map((_, index) =>(
                            <button 
                                key={index}
                                type={"button"}
                                className={`${index == currentIndex ? ` ${styles.carousel_indicator_btn} ${styles.is_active}`: `${styles.carousel_indicator_btn}`} `}
                                onClick={()=>{goToSlide(index)}}
                           />
                        ))}
                </div>
        </div>
    )
}

export default CarouselP