import styles from "./index.module.css";

const CardIOT = ({title, image_url, content_first, content_sec}) => {
    return (
        <div className={styles.container}>
            <img src={image_url} alt={"iot card pic"} />
            <div className={styles.card}>
                <h1 className={styles.heading}>{title}</h1>
                <p className={styles.description}>
                    {content_first}
                    <br /><br />
                    {content_sec}
                </p>
            </div>
        </div>
    )
}

export default CardIOT;
