import styles from './index.module.css';
import logo from "../../Assets/Homepage/DT_logo/dt_logo.svg";
import { FaTwitter, FaFacebook, FaInstagram, FaLinkedin, FaMedium} from "react-icons/fa";
import { CgCopyright } from 'react-icons/cg';
import { Link, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const Footer = () => {
    let currentPath = useLocation();
    currentPath = (currentPath.pathname + currentPath.hash).trim();
    return (
        <>
            <footer className={styles.footer_container}>
                <div className={styles.footer_main}>
                    <div className={styles.footer_info}>
                        <img src={logo} alt={"company_logo"} />
                        <br /><br />
                        <address>
                            Sector-35, Noida,  Uttar Pradesh
                            <br />
                            India - 201301
                        </address>
                            <br /> 
                            <p className={styles.p}><span className={styles.email}>Email id</span>: info@dataturtles.com </p>
                    </div>
                    <div className={styles.footer_services}>
                        <h1 className={styles.heading}>Services</h1>
                        <p className={styles.p}>
                            <Link to={"/"}>
                                Artificial Intelligence
                            </Link>
                        </p>
                        <p className={styles.p}>
                            <Link to={"/what-we-do/internet-of-things"}>
                                Internet of Things (IoT)
                            </Link>
                        </p>
                        <p className={styles.p}>
                            <Link to={"/"}>
                                Hardware Development
                            </Link>
                        </p>
                        <p className={styles.p}>
                            <Link to={"/"}>
                                Software Development
                            </Link>
                        </p>
                        <p className={styles.p}>
                            <Link to={"/"}>
                                Digital Transformation
                            </Link>
                        </p>
                    </div>
                    <div>
                        <div className={styles.footer_links}>
                            <h1 className={styles.heading}>Quick Links</h1>
                            
                            <p className={styles.p}>
                                <HashLink smooth to={"/#how_we_work"} className={ currentPath === "/#how_we_work" ? ` ${styles.isActive}` : ` ${styles.isNotActive}`}>
                                    How we work
                                </HashLink>
                            </p>
                            <p className={styles.p}>
                                <HashLink smooth to={"/#services"} className={ currentPath === "/#services" ? ` ${styles.isActive}` : ` ${styles.isNotActive}`}>
                                    Services
                                </HashLink>
                            </p>
                            <p className={styles.p}>
                                <Link to={"/about"}>
                                    About Us
                                </Link>
                            </p>
                            <div className={styles.p}>
                                <Link 
                                    to={"/#tally-contact"}
                                    className={styles.btn} 
                                    data-tally-open="mB6vQm" 
                                    data-tally-layout="modal" 
                                    data-tally-align-left="1" 
                                    data-tally-hide-title="1" 
                                    data-tally-emoji-text="👋" 
                                    data-tally-emoji-animation="wave" 
                                    data-tally-auto-close="0"
                                >
                                Get In Touch
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className={styles.footer_social}>
                        <h1 className={styles.heading}>Connect with DataTurtles</h1>
                        <div className={styles.footer_social_icons}>
                            <Link to={"https://www.linkedin.com/company/dataturtles"}>
                                <FaLinkedin className={styles.social_icon}/>    
                            </Link>
                            <FaTwitter className={`${styles.social_icon} ${styles.hide}`}/>
                            <FaFacebook className={`${styles.social_icon} ${styles.hide}`}/>
                            <FaInstagram className={`${styles.social_icon} ${styles.hide}`}/>
                            <FaMedium className={`${styles.social_icon} ${styles.hide}`}/>
                        </div> 
                    </div>
                </div>
                <span className={styles.hr}></span>
                <hr className={styles.hr} />
                <div className={styles.copyright}>
                    <CgCopyright  className={styles.copy} />
                    <span className={styles.copy_p}>2022 DataTurtles. All Rights Reserved</span>
                </div>
            </footer>
        </>
    )
}

export default Footer
