import styles from './Service.module.css';
import ServiceCard from './ServiceCard';
import service_image from '../../../Assets/Homepage/services/iot.png';
import service_image1 from '../../../Assets/Homepage/services/ai.png';
import service_image2 from '../../../Assets/Homepage/services/software.png';
import service_image3 from '../../../Assets/Homepage/services/digital.png';
import service_image4 from '../../../Assets/Homepage/services/hardware.png';
import service_image5 from '../../../Assets/Homepage/services/staff.png';

const Services = ({title, sub_title}) => {
    return (
        <>
            <div id='services' className={styles.service_container}>
                <h1 className={styles.service_heading}>{title}</h1>
                <h2 className={styles.service_subheading}><strong>WE CAN HELP - </strong>{sub_title}</h2>
                <div className={styles.service_logos}>
                    <ServiceCard image={service_image} text={"Internet of Things (IoT)"} hrefValue={"/what-we-do/internet-of-things"} />
                    <ServiceCard image={service_image1} text={"Artificial Intelligence"} hrefValue={""} />
                    <ServiceCard image={service_image2} text={"Software Development"} hrefValue={""} /> 
                    <ServiceCard image={service_image3} text={"Digital Transformation"} hrefValue={""} />
                    <ServiceCard image={service_image4} text={"Hardware Engineering"} hrefValue={""} /> 
                    <ServiceCard image={service_image5} text={"Staff Augmentation"} hrefValue={""} /> 
                </div> 
            </div>  
        </>
    )
}

export default Services
