import {useState, useEffect} from 'react';
import classes from './index.module.css';
import {FaAngleLeft, FaAngleRight} from 'react-icons/fa';
import {CarouselIndicator, CarouselSlide } from './Indicator_Slider';
import Button from '../../Atom/Button';
import First_image from "../../../Assets/Homepage/How we work/meet.svg";
import Second_image from "../../../Assets/Homepage/How we work/onboarding.svg";
import Third_image from "../../../Assets/Homepage/How we work/implementation.svg";
import Forth_image from "../../../Assets/Homepage/How we work/deliver.svg";
import Fifth_image from "../../../Assets/Homepage/How we work/support.svg";
export const carouselSlidesData = [
    {
        image_url: First_image,
        title: "Meet and Greet",
        a: 'Understanding your business requirements.',
        b: 'Brainstorming technical aspects with our technology experts.',
    }, 
    {
        image_url: Second_image,
        title: "Formal Onboarding",
        a: "Discussing & Finalizing our deliverables with you.",
        b:	"Onboarding you with a formal legal agreement with details including costing, deliverables, and timelines.",
    },
    {
        image_url: Third_image,
        title: "Project Implementation",
        a: "Real-time project monitoring dashboard.",
        b: "Agile project implementation.",
    }, {
        image_url: Forth_image,
        title: "Final Project Hand Over",
        a: "End-to-end black box testing.", 
        b: "Send complete project report.",
        c:	"Knowledge Transfer of project.",
    }, 
    {
        image_url: Fifth_image,
        title: "Support and Maintenance",
        a: "Bug support service.",
        b: "Server monitoring service.", 
        c:	"Cloud monitoring service.",
    }, 
];
const CarouselCard = ({title}) =>{
    const slides = carouselSlidesData;
    // console.log("slides: ", slides);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [length, setLength] = useState(slides.length);
    const [touchPosition, setTouchPosition] = useState(null);

    const handleTouchStart = (e) => {
        const touchDown = e.touches[0].clientX
        setTouchPosition(touchDown)
    }
    const handleTouchMove = (e) => {
        const touchDown = touchPosition
    
        if(touchDown === null) {
            return
        }
    
        const currentTouch = e.touches[0].clientX
        const diff = touchDown - currentTouch
    
        if (diff > 5) {
            next()
        }
    
        if (diff < -5) {
            prev()
        }
    
        setTouchPosition(null)
    }
    const goToSlide = (index) => {
        if(index < length){
            setCurrentIndex(index)
        } else {
            setCurrentIndex(0)
        }
    }
    const  goToPrevSlide = (index) => {
        if(index == -1){
            setCurrentIndex(0)
        } else {
            setCurrentIndex(index)
        }
    }
    const next = () => {
        if (currentIndex < (length - 1)) {
            setCurrentIndex(prevState => prevState + 1)
        } else {
            setCurrentIndex(0);
        }
    }

    const prev = () => {
        if (currentIndex > 0) {
            setCurrentIndex(prevState => prevState - 1)
        }
    }
    
    useEffect(() => {
        setLength(slides.length)
    }, [slides]);

    return (
            <div id="how_we_work" className={classes.carousel_container}>
                <h1 className={classes.heading}>{title}</h1>
                <div style={{"width":"60%", "marginRight":'auto', "marginLeft": 'auto'}}>
                <div className={classes.carousel__dots}>
                    <Button className={`${classes.carousel__button_mob}`} onClick={() => goToPrevSlide(currentIndex - 1)}></Button>
                        {slides.map((_, index) =>
                                <CarouselIndicator
                                    key={index}
                                    index={index}
                                    isActive={currentIndex==index} 
                                    onClick={() => goToSlide(index)}
                                >
                                    {index + 1}
                                </CarouselIndicator>
                          
                        )}
                    <Button className={`${classes.carousel__button_mob}`} onClick={() => goToSlide(currentIndex+2)}></Button>
                    </div>
                </div>
                <div className={classes.carousel}>
                   
                    <div className={`${classes.carousel__slides}`}>
                        {slides.map((slide, index) =>(
                                <CarouselSlide
                                    key={index}
                                    index={index}
                                    activeIndex={currentIndex}
                                    slide={slide}
                                    onTouchStart={handleTouchStart} 
                                    onTouchMove={handleTouchMove}
                                />
                        ))}
                    </div>
                    <Button 
                        className={`${classes.carousel__button} ${classes.carousel__button__prev}`} 
                        onClick={() => prev()} 
                    >
                        <FaAngleLeft className={classes.btn_icon}/>
                    </Button>
                    <Button 
                        className={`${classes.carousel__button} ${classes.carousel__button__next}`} 
                        onClick={()=>next()} 
                    >
                        <FaAngleRight className={classes.btn_icon} />
                    </Button>   
                </div>
            </div>
        );
    }


export default CarouselCard;

