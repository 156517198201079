import Button from "../../Atom/Button";
import styles from "./index.module.css";

export const data = [
    {
        heading: "Connectivity protocols",
        content: "We specialize in developing wired and wireless IoT solutions. Some of the wired protocols that we have worked are: Modbus (TCP/IP), CAN, RS485, Ethernet, etc. and some of the wireless protocols that we have worked with are: 2G/3G/4G, Zigbee, Z-Wave, WiFi, Ethernet, Thread, BLE, LoRaWAN, etc. ",
    }, 
    {
        heading: "Cloud platforms", 
        content: "Managing the devices, firmware update over the air, and many other advanced feature require us to get the data from IoT devices to a cloud platform of choice. We have worked with AWS IoT, Azure IoT, Google IoT, TTI network server (LoRaWAN), and many other cloud platforms.",
    },
    {
        heading: "AI on cloud",
        content: "The raw data from IoT devices does not make sense to a general user. We specialize in building and deploying IoT models that derive useful insights from the collected data which will help user in saving money and time.",
    },
    {
        heading: "AI on Edge", 
        content: "Sometimes there is bandwidth constraint and we can not send data to cloud in realtime. Also, pushing data to cloud continuously is a costly affair. The controllers on the IoT devices are powerful enough to run a small neural net at the edge. We specialize in building and deploying TinyML models.",
    },
    {
        heading: "Mobile App/ Web App/ Custom Dashboards",
        content: "Once the IoT data is processed in the cloud, a user would like to see the data in a desirable platform. We specialize in building cross-platform visual interfaces.",
    },
];
const ExpertiseCard = ({title, button_value}) => {
    return (
        <div className={styles.container}>
            <div className={styles.card_container}>
                <div className={styles.card_left}>
                    <h1 className={styles.heading}>{title}</h1>
                    <Button className={styles.btn} 
                    // onClick={()=>Calendly.initPopupWidget({url: 'https://calendly.com/atuldubey/introduction-to-dataturtles'})}
                    >
                            {button_value}
                    </Button>
                </div>
                <div className={styles.card_right}>
                {data.map((item, i)=>{
                    return (
                        <div className={styles.card} key={i}>
                            <h1 className={styles.sub_heading}>{item.heading}</h1>
                            <p className={styles.description}>
                                {item.content}
                            </p>
                        </div>
                    )
                })}
                </div>
            </div>
        </div>
    )
}

export default ExpertiseCard;