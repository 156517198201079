import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home } from './pages/Home';
import { HelmetProvider } from 'react-helmet-async';
import Navigation from './Components/Navigation';
import Footer from './Components/Footer';
import { About } from './pages/About';
import { AI_ML } from './pages/what-we-do/AI_ML';
import { DigitalTransformation } from './pages/what-we-do/DigitalTransformation';
import { EmbeddedHardware } from './pages/what-we-do/EmbeddedHardware';
import { InternetOfThings } from './pages/what-we-do/InternetOfThings';
import { SoftwareEngineering } from './pages/what-we-do/SoftwareEngineering';
import { StaffAugmentation } from './pages/what-we-do/StaffAugmentation';

function App() {
  return (
    <div>
      <HelmetProvider>
        <BrowserRouter >  
          <Navigation />
          <Routes> 
            <Route path="/" exact element={<Home />} />
            <Route path="/about" exact element={<About />} />
            <Route path="what-we-do/ai-ml-and-data-science-service" exact element={<AI_ML />} />
            <Route path="what-we-do/digital-transformation" exact element={<DigitalTransformation />} />
            <Route path="what-we-do/embedded-and-hardware-engineering" exact element={<EmbeddedHardware />} />
            <Route path="what-we-do/internet-of-things" exact element={<InternetOfThings />} />
            <Route path="what-we-do/software-engineering-services" exact element={<SoftwareEngineering />} />
            <Route path="what-we-do/staff-augmentation" exact element={<StaffAugmentation />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </HelmetProvider>
    </div>
  );
}

export default App;
