import styles from "./Service.module.css";
import { Link, useNavigate } from "react-router-dom";



const ServiceCard = (props) => {
    const navigate = useNavigate();
    return (
        <>
        <Link to={props.hrefValue}>
            <div className={styles.service_card}>
                <div className={styles.image_card}>
                    <img 
                        className={styles.service_image} 
                        src={props.image} 
                        alt={'why us'}
                    />
                </div>
                <div>
                    <p className={styles.service_card_p}>{props.text}</p>
                </div>
                <div>
                    <button className={styles.btn} onClick={()=>navigate(props.hrefValue)} >Know more</button>
                </div>
            </div>  
        </Link>
        </>
    )
}

export default ServiceCard
