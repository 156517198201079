import classes from './index.module.css';

export const CarouselIndicator = ({children, isActive, ...rest}) => {
    return (
        <>
            <button 
                type={"button"}
                className={`
                ${classes.carousel__dot} 
                ${isActive ? `${classes.is_selected}` : ""}
                ${isActive ? `${classes.carousel__dot_mob} ${classes.is_selected}`: ""}`}
                {...rest}
            >
                {children}
            </button>
            <hr className={classes.dotted} />
        </>
    )
}


export const CarouselSlide = ({ onTouchStart, onTouchMove, ...props}) => {
    return (
        <>
            <div 
                className={
                    props.index == props.activeIndex
                    ? `${classes.carousel__slide} ${classes.carousel__slide__active}`
                    : `${classes.carousel__slide}`
                }
            >
                <div className={classes.carousel__slide_outer_div}>
                <h1 className={classes.carousel__slide__title}>{props.slide.title}</h1>
                <div className={classes.carousel__slide_image_size} onTouchStart={onTouchStart} onTouchMove={onTouchMove}>
                    <img
                        src={props.slide.image_url} 
                        alt='Carousel image' 
                    />
                </div>
                <div className={classes.carousel_slide__content}>
                    <p className={classes.carousel_slide__content_p}>a. {props.slide.a}</p>
                    <p className={classes.carousel_slide__content_p}>b. {props.slide.b}</p>
                    {props.slide.c ? <p className={classes.carousel_slide__content_p}>c. {props.slide.c}</p>: ''}
                </div>
               </div>
            </div>
        </>
    )
}
