import React, { useEffect } from 'react';
import HeroSection from '../../Components/Card/Hero';
import Hero_Image from '../../Assets/Homepage/hero.png';
import Image from '../../Assets/IoT/iot.svg';
import TrustedBy from '../../Components/Card/TrustedBy';
import CardIOT from '../../Components/Card/CardIot';
import ExpertiseCard from '../../Components/Card/ExpertiseCard';
import Portfolio from '../../Components/Card/Portfolio';
import SEO from '../../Components/Seo';


export const InternetOfThings = () => {
    useEffect(()=>{
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    },[]);
    return (
        <>
            <SEO
                title={"DataTurtles | Internet of Things (IoT)"} 
                description={`IoT and the Machine-to-Machine - M2M technology is bringing together a next revolution 
                to alter the way we learn, work, entertain and innovate to nearly every industry.`} 
            />
            <main style={{width: "100%", height: "100%"}}>
                <HeroSection 
                    Hero_Image={Hero_Image}
                    text_content={"Robust, Rapid & Secured IoT hardware Solutions"}
                    button_value={"Book a free consultation"}
                /> 
                <TrustedBy title={"Trusted By"} />    
                <CardIOT 
                    title={"IoT Solution Development"} 
                    image_url={Image}
                    content_first={`By the year 2025, it is projected that more than 30.9 billion devices will be connected to the Internet. 
                        Not only just the smartphones and tablets, but almost anything and everything with a sensor on it like, wearable devices, 
                        production plant machines, coffee makers, car jet engines, oil drills or anything will be going to associate with the internet.
                        This interconnecting network is known as the Internet of Things - IoT.`}
                    content_sec={`Internet of Things - IoT and the Machine-to-Machine - M2M technology is bringing together a next revolution 
                        to alter the way we learn, work, entertain and innovate to nearly every industry. Making use of IoT technologies 
                        and applications, businesses could achieve virtually endless possibilities. At present, IoT devices are the 
                        technological innovation fruits that have only begun to ripen. Internet of Things technology has a great potential 
                        to generate a fantastic economic impact.`
                    }
                />
                <ExpertiseCard
                     title={"IoT Services to build a connected world"} 
                     button_value={"Book a free consultation"}
                />
                <Portfolio title={"Portfolio"} iot={true}/>
            </main>
        </>
    )
}