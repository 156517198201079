import React, { useEffect } from 'react'
import Hero_Image from '../Assets/Homepage/hero.png';
import CoreTeam from '../Components/Card/CoreTeam';
import Experience from '../Components/Card/Experience';
import HeroSection from '../Components/Card/Hero';
import Purpose from '../Components/Card/Purpose';
import Story from '../Components/Card/Story';
import SupportedBy from '../Components/Card/SupportedBy';
import SEO from '../Components/Seo';

export const About = () => {
    useEffect(()=>{
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);
    return (
        <>
            <SEO 
                title={"DataTurtles | About"} 
                description={"We are a services company consisting of a team of young and enthusiastic professionals with experience in IoT, Hardware design, AI/ML, Data Science, Web Development, App Development, and Cyber Security. We have partnered with a manufacturing company for our client’s electronics manufacturing requirements."} 
            />
            <main style={{width: "100%", height: "100%"}}>
                <HeroSection 
                    about={true}
                    Hero_Image={Hero_Image} 
                    text_content={"Enabling Business with Technology"}
                    button_value={"Book a free consultation"}
                />
                <Story title={"Our Story"} />
                <Experience />
                <Purpose title={"Core Purpose and Values"} /> 
                <CoreTeam title={"Core Team"} />
                <SupportedBy title={"Supported by"} />
            </main>
        </>
    )
}
