import styles from "./index.module.css";

const Story = ({title}) => {
    const data = [
        {
            content: "We are a services company consisting of a team of young and enthusiastic professionals with experience in IoT, Hardware design, AI/ML, Data Science, Web Development, App Development, and Cyber Security. We have partnered with a manufacturing company for our client’s electronics manufacturing requirements.",
        }, 
        {
            content: "We work closely and transparently with our clients to understand their business use-cases, suggesting the best possible solutions with optimized cost & timely delivery.",
        },
    ];
    return (
        <div className={styles.container}>
            <h1 className={styles.heading}>{title}</h1>
            <div className={styles.card_container}>
                {data.map((item, i) => {
                    return (
                        <p key={i} className={styles.description}>{item.content}</p>
                    )
                })}
            </div>
        </div>
    )
}
export default Story;