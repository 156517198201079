import styles from "./WhyUs.module.css";
const WhyUsCard = (props) => {
    return (
        <>
            <div className={styles.whyus_card}>
                <div className={styles.image_card}>
                    <img 
                        className={styles.whyus_image} 
                        src={props.image} 
                        alt={'why us'}
                    />
                </div>
                <div>
                    <p className={styles.whyus_card_p}>{props.text}</p>
                </div>
            </div>
        </>
    )
}

export default WhyUsCard
