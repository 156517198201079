import styles from './index.module.css';

const Purpose = ({title}) => {
    const data = [
        {
            heading: "Excellent",
            content: "You add value to your customer when you deliver a product or service (and the associated brand experience) that has been designed specifically to solve their problem.",
        }, 
        {
            heading: "Sustainability", 
            content: "Agile is not a principal or a method, but it’s an integral part of being Agile that is guided by principles, defined by values and manifested through various practices.",
        },
        {
            heading: "Innovative",
            content: "Core Team will work as Scrum Team where Team will have quarterly goal to make sure that we run financial, administrative and project management prospective.",
        }
    ];
    return (
        <div className={styles.container}>
            <h1 className={styles.heading}>{title}</h1>
            <div className={styles.card_container}>
                {data.map((item, i)=>{
                    return (
                        <div key={i}>
                            <h2 className={styles.sub_heading}>{item.heading}</h2>
                            <p className={styles.description}>
                               {item.content}
                            </p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default Purpose
